import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

import { articleLoader } from '@shared/libs/allLoader'
import Error from '@shared/components/error'
import Layout from '@shared/components/layout'

// const HomePageContent = dynamic(() => import('@shared-components/homePageComponents/homePageContent'), { loading: () => pageLoading() })
// const HomePageArticle = dynamic(() => import('@shared-components/homePageComponents/homePageArticle'), {
//   loading: () => articleLoader(['g', 's'])
// })
const HomePageFantasyArticle = dynamic(() => import('@shared/components/homePageComponents/homePageFantasyArticle'), {
  loading: () => articleLoader(['g', 's']),
  ssr: false
})
const HomePageVideo = dynamic(() => import('@shared/components/homePageComponents/homePageVideo'), {
  loading: () => articleLoader(['g', 's']),
  ssr: false
})
const HomePageArticle = dynamic(() => import('@shared/components/homePageComponents/homePageArticle'))
const HomePageContent = dynamic(() => import('@shared/components/homePageComponents/homePageContent'))

function Home({ articles, widgetPosition, miniScorecard, scorecardHeader, stories, widgetData }) {
  function getMetaDetail() {
    return {
      oSeo: {
        sTitle: 'Cricket Live Score, Cricket News, Predictions, Stats | CricTracker',
        sDescription: 'Live Cricket: Get live cricket scores, news, schedules, highlights, commentary, rankings, videos, stats, & more updates for all international & domestic cricket matches.',
        aKeywords: ['cricket', 'cricket live score', 'live score', 'cricket score', 'cricket news', 'cricket live', 'today cricket matches', 'cricket schedules', 'live cricket', 'ball by ball commentary', 'ind live score', 'cricket stats', 'cricket records', 'dream11', 'match prediction', 'cricket highlights', 'cricket preview', 'cricket videos', 'icc player rankings', 'BCCI news', 'ICC news']
      }
    }
  }

  return (
    <Layout data={getMetaDetail()}>
      <HomePageContent
        articles={articles}
        widgetPosition={widgetPosition}
        miniScorecard={miniScorecard}
        scorecardHeader={scorecardHeader}
        stories={stories}
        widgetData={widgetData}
      >
        {(type) => {
          if (type === 'fa') return <HomePageFantasyArticle />
          else if (type === 'v') return <HomePageVideo />
          else return <HomePageArticle articles={articles} widgetPosition={widgetPosition} widgetData={widgetData} />
        }}
      </HomePageContent>
    </Layout>
  )
}

Home.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  widgetPosition: PropTypes.arrayOf(PropTypes.object),
  miniScorecard: PropTypes.arrayOf(PropTypes.object),
  scorecardHeader: PropTypes.arrayOf(PropTypes.object),
  stories: PropTypes.arrayOf(PropTypes.object),
  widgetData: PropTypes.object
}

export default Error(Home)

export async function getServerSideProps({ res, query, resolvedUrl }) {
  try {
    if (query?.p) {
      try {
        const getRedirectionURL = (await import('../shared/libs/homePage')).redirectToArticleWhenQueryHaveID
        const { returnObj } = await getRedirectionURL(query?.p)
        return returnObj
      } catch (e) {
        return { notFound: true }
      }
    } else {
      res.setHeader('Cache-Control', 'public, max-age=600')
      const homePageFunction = (await import('../shared/libs/homePage')).getHomePageData
      const data = await homePageFunction('a')
      return {
        props: data
      }
    }
  } catch (e) {
    // console.dir({ homepage: e }, { depth: null })
    res.setHeader('Cache-Control', 'no-cache')
    const handleApiError = (await import('@shared/utils')).handleApiError
    const status = handleApiError(e, resolvedUrl)
    return status
  }
}
